window.__ = (a) => a;
import React from "react";
import { startApp } from "@discovery/client-core";
import "@discovery/components-dplus/lib/base.css";
import { DEVELOPMENT_MODE, LOG_WHY_DID_YOU_RENDER, } from "@discovery/common-dplus/lib/env";
if (LOG_WHY_DID_YOU_RENDER && DEVELOPMENT_MODE) {
    import("@welldone-software/why-did-you-render").then(({ default: whyDidYouRender }) => {
        whyDidYouRender(React, {
            trackAllPureComponents: true,
            collapseGroups: true,
        });
    });
}
startApp(() => import("./template-engine").then((x) => x.main));
